import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Action } from '@models';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-active-users',
  standalone: true,
  imports: [],
  templateUrl: './active-users.component.html',
  styleUrl: './active-users.component.scss',
})
export class ActiveUsersComponent implements AfterViewInit, OnChanges {
  @Input() public actions: Action[] | null = [];

  @ViewChild('lineChart') private lineChartRef!: ElementRef<HTMLCanvasElement>;
  private lineChart!: Chart<'line'>;
  private filteredLogs?: Action[] = [];

  ngAfterViewInit(): void {
    this.filterLogs(30);
    this.createLineChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.lineChart) return;
    this.filterLogs(30);
    this.lineChart.destroy();
    this.createLineChart();
  }

  private createLineChart(): void {
    const labels = this.getActiveUserDates();
    const data = this.getDailyActiveUserCounts();

    this.lineChart = new Chart(this.lineChartRef.nativeElement, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Daily Active Users',
            data: data,
            borderColor: 'rgb(76, 217, 100)',
            backgroundColor: 'rgba(76, 217, 100, 0.2)',
            fill: true,
          },
        ],
      },
      options: {
        color: 'white',
        responsive: true,
        scales: {
          x: { title: { display: true, text: 'Date' } },
          y: {
            title: { display: true, text: 'Active Users' },
            beginAtZero: true,
            ticks: {
              stepSize: 1,
              callback: (value) => value.toString(),
            },
          },
        },
      },
    });
  }

  private getActiveUserDates(): string[] {
    const dateSet = new Set<string>();

    this.filteredLogs?.forEach((log) => {
      dateSet.add(new Date(log.createdAt!).toLocaleDateString());
    });

    return Array.from(dateSet).sort();
  }

  private getDailyActiveUserCounts(): number[] {
    const dateMap = new Map<string, number>();

    this.filteredLogs?.forEach((log) => {
      const date = new Date(log.createdAt!).toLocaleDateString();
      dateMap.set(date, (dateMap.get(date) || 0) + 1);
    });

    const labels = this.getActiveUserDates();
    return labels.map((label) => dateMap.get(label) || 0);
  }

  private filterLogs(days: number): void {
    const now = new Date();
    const pastDate = new Date();
    pastDate.setDate(now.getDate() - days);

    this.filteredLogs = this.actions?.filter((log) => {
      const logDate = new Date(log.createdAt!);
      return logDate >= pastDate && logDate <= now;
    });
  }

  onFilterChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const days = parseInt(selectElement.value, 10);
    this.filterLogs(days);
    this.lineChart.destroy();
    this.createLineChart();
  }
}
