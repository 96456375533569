<div *ngIf="signature && contract; else noSignature" style="height: 100%">
  <mat-tab-group style="height: 100%">
    <mat-tab label="Contract" style="height: 100%">
      <div class="mt-3">
        <div
          *ngIf="contractOutOfDate"
          style="width: 100%; background-color: orange; padding: 8px"
        >
          <p style="color: white; text-align: center; margin-top: 8px">
            Contract out of date!
          </p>
        </div>
        <display-input title="Contract Title" [value]="contract.title" />
        <display-input title="Contract Version" [value]="contract.version" />
      </div>
      <embed
        [src]="safeContractUrl"
        type="application/pdf"
        width="100%"
        height="500"
      />
    </mat-tab>
    <mat-tab label="Signature">
      <div class="mt-3">
        <display-input title="Signed At" [value]="signature.signedAt" />
        <display-input title="Signed On" [value]="signedOn" />
        <img [src]="signature.signatureUrl" />
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #noSignature>
  <div class="d-flex justify-content-center align-items-center">
    <div class="text-center">
      <div class="d-flex align-items-center" style="height: 80vh">
        <div>
          <mat-icon class="me-2">article</mat-icon>
          <div>Contract not signed</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
