<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{ isEditMode ? 'Update Contract' : 'Create Contract' }}
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="contractForm" (ngSubmit)="onSubmit()" class="mt-4">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Version</mat-label>
        <input matInput formControlName="version" type="text" />
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Title</mat-label>
        <input matInput formControlName="title" type="text" />
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>

      @if(!uploadPreview){
      <div class="file-upload mt-4">
        <label for="file">Upload Document (PDF):</label>
        <div
          class="file-dropzone p-5"
          (click)="fileInput.click()"
          (drop)="onDrop($event)"
        >
          <div style="text-align: center">
            <p>Drag and drop your PDF here, or click to select</p>
            <mat-icon class="drop-icon">article</mat-icon>
          </div>
        </div>
        <input
          #fileInput
          id="file"
          type="file"
          accept="application/pdf"
          (change)="onFileSelected($event)"
          hidden
        />
      </div>
      } @if(uploadPreview){
      <div class="mt-2">
        <div class="close-icon" (click)="closePreview()">
          <mat-icon matListItemIcon>close</mat-icon>
        </div>
        <p>Preview:</p>
        <embed
          [src]="uploadPreview"
          type="application/pdf"
          width="100%"
          height="600"
        />
      </div>
      }

      <div class="d-flex align-items-center justify-content-end mt-3">
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="contractForm.invalid || (!file&&!uploadPreview)"
        >
          {{ isEditMode ? 'Update Contract' : 'Create Contract' }}
        </button>
        <mat-progress-spinner
          *ngIf="loading"
          mode="indeterminate"
          color="primary"
          diameter="30"
          class="ml-3"
        ></mat-progress-spinner>
      </div>
      @if (error) {
      <app-alert (close)="error = null" class="mt-3" type="error">
        {{error}}
      </app-alert>
      }
    </form>
  </mat-card-content>
</mat-card>
