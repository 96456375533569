import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ContractState } from '@state';

export const selectContractState =
  createFeatureSelector<ContractState>('contract');

export const selectContract = createSelector(
  selectContractState,
  (state: ContractState) => {
    return state.contract;
  }
);

export const selectContractFetchedAt = createSelector(
  selectContractState,
  (state: ContractState) => state.fetchedAt
);
