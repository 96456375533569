import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { DocumentTypeService } from '@services';
import { selectRouteState } from '@state/route';
import * as DocumentTypeActions from './document-type.actions';

@Injectable()
export class DocumentTypeEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private documentTypeService: DocumentTypeService
  ) {}

  // Create
  createDocumentType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentTypeActions.createDocumentType),
      switchMap(({ documentType }) =>
        from(this.documentTypeService.create(documentType)).pipe(
          map((documentType) =>
            DocumentTypeActions.createDocumentTypeSuccess({ documentType })
          ),
          catchError((error) =>
            of(DocumentTypeActions.createDocumentTypeFailed({ error }))
          )
        )
      )
    )
  );

  //Read
  fetchDocumentTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentTypeActions.fetchDocumentTypes),
      switchMap(() =>
        from(this.documentTypeService.get()).pipe(
          map((documentTypes) =>
            DocumentTypeActions.fetchDocumentTypesSuccess({
              documentTypes,
            })
          ),
          catchError((error) =>
            of(DocumentTypeActions.fetchDocumentTypesFailed({ error }))
          )
        )
      )
    )
  );

  fetchDocumentType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentTypeActions.fetchDocumentTypeById),
      switchMap(({ id }) =>
        from(this.documentTypeService.getById(id)).pipe(
          map((documentType) =>
            DocumentTypeActions.fetchDocumentTypeByIdSuccess({ documentType })
          ),
          catchError((error) =>
            of(DocumentTypeActions.fetchDocumentTypeByIdFailed({ error }))
          )
        )
      )
    )
  );

  fetchCurrentDocumentType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentTypeActions.fetchCurrentDocumentType),
      mergeMap(() => this.store.pipe(select(selectRouteState), take(1))),
      switchMap((route) => {
        const documentTypeId = route.params['documentTypeId'];

        if (documentTypeId === 'new') {
          const documentType = null;
          return of(
            DocumentTypeActions.fetchDocumentTypeByIdSuccess({ documentType })
          );
        } else {
          return of(
            DocumentTypeActions.fetchDocumentTypeById({ id: documentTypeId })
          );
        }
      })
    )
  );

  // Update
  updateDocumentType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentTypeActions.updateDocumentType),
      switchMap(({ documentType }) =>
        from(this.documentTypeService.update(documentType)).pipe(
          map((documentType) =>
            DocumentTypeActions.updateDocumentTypeSuccess({ documentType })
          ),
          catchError((error) =>
            of(DocumentTypeActions.updateDocumentTypeFailed({ error }))
          )
        )
      )
    )
  );

  // Delete
  deleteDocumentType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentTypeActions.deleteDocumentType),
      switchMap(({ id }) =>
        from(this.documentTypeService.delete(id)).pipe(
          map(() => DocumentTypeActions.deleteDocumentTypeSuccess({ id })),
          catchError((error) =>
            of(DocumentTypeActions.deleteDocumentTypeFailed({ error }))
          )
        )
      )
    )
  );
}
