import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { User } from '@models';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

@Component({
  selector: 'app-users-by-country',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './users-by-country.component.html',
  styleUrls: ['./users-by-country.component.scss'],
})
export class UsersByCountryComponent implements AfterViewInit, OnChanges {
  @Input() public users: User[] | null = [];

  @ViewChild('barChart') private barChartRef!: ElementRef<HTMLCanvasElement>;
  private barChart!: Chart<'bar'>;

  ngAfterViewInit(): void {
    this.createBarChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.barChart) return;
    this.barChart.destroy();
    this.createBarChart();
  }

  private createBarChart(): void {
    const { countries, counts } = this.getUsersByCountry();

    this.barChart = new Chart(this.barChartRef.nativeElement, {
      type: 'bar',
      data: {
        labels: countries,
        datasets: [
          {
            label: 'Drivers by Country',
            data: counts,
            backgroundColor: 'rgba(76, 217, 100, 0.6)',
            borderColor: 'rgba(76, 217, 100, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        indexAxis: 'y',
        scales: {
          x: {
            title: { display: true, text: 'Number of Drivers' },
            beginAtZero: true,
          },
          y: {
            title: { display: true, text: 'Country' },
            ticks: { font: { size: 30 } },
          },
        },
      },
    });
  }

  private getUsersByCountry(): { countries: string[]; counts: number[] } {
    const countryCounts = new Map<string, number>();

    this.users
      ?.filter((user) => user.profile?.nationality)
      .forEach((user) => {
        const country = user.profile!.nationality.split(' ')[0];
        countryCounts.set(country, (countryCounts.get(country) || 0) + 1);
      });

    const sortedEntries = Array.from(countryCounts.entries()).sort(
      (a, b) => b[1] - a[1]
    );

    const topEntries = sortedEntries.slice(0, 10);

    const countries = topEntries.map(([country]) => country);
    const counts = topEntries.map(([, count]) => count);

    return { countries, counts };
  }
}
