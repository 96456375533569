import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DisplayInputComponent } from '@components';
import { Contract, Signature, User } from '@models';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-signed-contract',
  standalone: true,
  imports: [CommonModule, DisplayInputComponent, MatTabGroup, MatTab, MatIcon],
  templateUrl: './signed-contract.component.html',
  styleUrls: ['./signed-contract.component.scss'],
})
export class SignedContractComponent {
  @Input() user: User | null = null;
  @Input() latestContract: Contract | null = null;

  constructor(private store: Store, private sanitizer: DomSanitizer) {}

  public get contract(): Contract | undefined {
    return this.signature?.contract;
  }

  public get signature(): Signature | undefined {
    return this.user?.contractSignature;
  }

  public get signedOn(): string {
    return JSON.stringify(this.signature?.createdAt).substring(1, 11);
  }

  public get contractUrl(): string {
    return this.contract?.fileUrl ?? '';
  }

  public get safeContractUrl(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.contractUrl);
  }

  public get contractOutOfDate(): boolean {
    return this.user?.contractSignature?.contractId !== this.latestContract?.id;
  }
}
