import { Injectable } from '@angular/core';

import { Contract } from '@models';
import { CrudService, HttpService } from '@services';

@Injectable({ providedIn: 'root' })
export class ContractService extends CrudService<Contract> {
  constructor(http: HttpService) {
    super('contract', http);
  }

  async getLatest(): Promise<Contract> {
    const response = await this.http.get<Contract>(`${this.url}/latest`);
    if (!response.success) throw new Error(response.message);
    return response.data;
  }

  async createContract(data: Partial<Contract>, file?: File): Promise<Contract> {
    const response = await this.http.postWithFile<Contract>(
      `${this.url}/`,
      data,
      file
    );
    if (!response.success) throw new Error(response.message);
    return response.data;
  }

  async updateContract(
    data: Partial<Contract>,
    file?: File
  ): Promise<Contract> {
    const response = await this.http.putWithFile<Contract>(
      `${this.url}/` + data.id,
      data,
      file
    );
    if (!response.success) throw new Error(response.message);
    return response.data;
  }

  override create(data: Partial<Contract>): Promise<Contract> {
    throw new Error('Method not available.');
  }

  override update(data: Partial<Contract>): Promise<Contract> {
    throw new Error('Method not available.');
  }

  override get(): Promise<Contract[]> {
    throw new Error('Method not available.');
  }

  override getById(id: string): Promise<Contract> {
    throw new Error('Method not available.');
  }
}
