import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { AlertComponent, DialogComponent } from '@components';
import { Expense } from '@models';
import { UtilService } from '@services';
import {
  createExpense,
  createExpenseFailed,
  createExpenseSuccess,
  deleteExpense,
  deleteExpenseFailed,
  deleteExpenseSuccess,
  fetchCurrentExpense,
  selectCurrentExpense,
  selectExpensesFetchedAt,
  updateExpense,
  updateExpenseFailed,
  updateExpenseSuccess,
} from '@state';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    AlertComponent,
  ],
  templateUrl: './expense.page.html',
  styleUrl: './expense.page.scss',
})
export class ExpensePage implements OnInit, OnDestroy {
  readonly dialog = inject(MatDialog);

  public loading: boolean = false;
  public error: string | null = null;
  public expense: Expense = new Expense();
  private sub: Subscription | null = null;

  public $fetchedAt = this.store.select(selectExpensesFetchedAt);
  private $expense = this.store.select(selectCurrentExpense);

  public form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    value: new FormControl(0, [Validators.required, Validators.min(1)]),
  });

  constructor(
    private store: Store,
    private router: Router,
    private utils: UtilService
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.dispatch(fetchCurrentExpense());

    this.sub = this.$expense.subscribe((expense) => {
      if (!expense) return;
      this.expense = expense;
      this.populateForm(expense);
    });
  }

  private populateForm(expense: Expense): void {
    this.form.patchValue({
      name: expense.name,
      description: expense.description,
      value: expense.value,
    });
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }

  public getFormControl(name: string): FormControl {
    return this.form.get(name) as FormControl;
  }

  public async onFormSubmit(): Promise<void> {
    if (!this.form.valid) {
      this.error = 'Please fill in all required fields';
      return;
    }

    this.loading = true;

    try {
      const expense: Expense = {
        ...this.expense,
        name: this.getFormControl('name').value,
        description: this.getFormControl('description').value,
        value: this.getFormControl('value').value,
      };

      if (!this.expense.id) {
        await this.createExpense(expense);
      } else {
        await this.updateExpense(expense);
      }

      this.dialog
        .open(DialogComponent, {
          data: {
            title: 'Success',
            message: 'Expense saved successfully',
          },
        })
        .afterClosed()
        .subscribe(() => {
          this.router.navigate(['/expenses']);
        });
    } catch (err: any) {
      this.error = this.utils.getErrorMessage(err);
    }

    this.loading = false;
  }

  private async createExpense(expense: Expense): Promise<void> {
    await this.utils.actionsToPromise(
      createExpense({ expense }),
      createExpenseSuccess,
      createExpenseFailed
    );
  }

  private async updateExpense(expense: Expense): Promise<void> {
    await this.utils.actionsToPromise(
      updateExpense({ expense }),
      updateExpenseSuccess,
      updateExpenseFailed
    );
  }

  public onDeleteExpense(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Delete Expense',
        message: 'Are you sure you want to delete this expense?',
        cancel: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.deleteExpense(this.expense);
    });
  }

  private async deleteExpense(expense: Expense): Promise<void> {
    this.loading = true;

    try {
      await this.utils.actionsToPromise(
        deleteExpense({ id: expense.id! }),
        deleteExpenseSuccess,
        deleteExpenseFailed
      );

      this.router.navigate(['/expenses']);
    } catch (err: any) {
      this.error = this.utils.getErrorMessage(err);
    }

    this.loading = false;
  }
}
