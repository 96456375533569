import { Contract } from '@models';

export interface ContractState {
  contract: Contract | null;
  fetchedAt: Date | null;
}

export const initialContractState: ContractState = {
  contract: null,
  fetchedAt: null,
};
