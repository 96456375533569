import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { User } from '../../../models';

Chart.register(...registerables);

@Component({
  selector: 'app-sign-ups',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sign-ups.component.html',
  styleUrls: ['./sign-ups.component.scss'],
})
export class SignUpsComponent implements AfterViewInit, OnChanges {
  @Input() public users: User[] | null = [];

  @ViewChild('lineChart') private lineChartRef!: ElementRef<HTMLCanvasElement>;
  private lineChart!: Chart<'line'>;
  private filteredUsers?: User[] = [];

  ngAfterViewInit(): void {
    this.filterSignUps(30);
    this.createLineChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.lineChart) return;
    this.filterSignUps(30);
    this.lineChart.destroy();
    this.createLineChart();
  }

  private createLineChart(): void {
    const labels = this.getUserSignupDates();
    const data = this.getTotalUserCounts();

    this.lineChart = new Chart(this.lineChartRef.nativeElement, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Total Sign-Ups',
            data: data,
            borderColor: 'rgba(76, 217, 100, 1)',
            backgroundColor: 'rgba(76, 217, 100, 0.2)',
            fill: true,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: { title: { display: true, text: 'Date' } },
          y: {
            title: { display: true, text: 'Total Sign-Ups' },
            beginAtZero: true,
            ticks: {
              stepSize: 1,
              callback: (value) => value.toString(),
            },
          },
        },
      },
    });
  }

  private getUserSignupDates(): string[] {
    const datesSet = new Set<string>();

    this.filteredUsers?.forEach((user) => {
      const date = new Date(user.createdAt!).toLocaleDateString();
      datesSet.add(date);
    });

    return Array.from(datesSet).sort();
  }

  private getTotalUserCounts(): number[] {
    const datesMap = new Map<string, number>();

    this.filteredUsers?.forEach((user) => {
      const date = new Date(user.createdAt!).toLocaleDateString();
      datesMap.set(date, (datesMap.get(date) || 0) + 1);
    });

    const labels = this.getUserSignupDates();

    let cumulativeCount = 0;

    return labels.map((label) => {
      cumulativeCount += datesMap.get(label) || 0;
      return cumulativeCount;
    });
  }

  private filterSignUps(days: number): void {
    const now = new Date();
    const pastDate = new Date();
    pastDate.setDate(now.getDate() - days);

    this.filteredUsers = this.users?.filter((user) => {
      const userDate = new Date(user.createdAt!);
      return userDate >= pastDate && userDate <= now;
    });
  }

  onFilterChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const days = parseInt(selectElement.value, 10);
    this.filterSignUps(days);
    this.lineChart.destroy();
    this.createLineChart();
  }
}
