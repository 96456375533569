import { createAction, props } from '@ngrx/store';

import { Contract } from '@models';

// Create Contract:
export const createContract = createAction(
  '[Contract] Create Initiated',
  props<{ contract: Contract; file?: File }>()
);
export const createContractSuccess = createAction(
  '[Contract] Create Success',
  props<{ contract: Contract }>()
);
export const createContractFailed = createAction(
  '[Contract] Create Failed',
  props<{ error: Error }>()
);

// Fetch Contract:
export const fetchLatestContract = createAction('[Contract] Fetch Initiated');
export const fetchLatestContractSuccess = createAction(
  '[Contract] Fetch Success',
  props<{ contract: Contract }>()
);
export const fetchLatestContractFailed = createAction(
  '[Contract] Fetch Failed',
  props<{ error: Error }>()
);

// Update Contract:
export const updateContract = createAction(
  '[Contract] Update Initiated',
  props<{ contract: Contract; file?: File }>()
);
export const updateContractSuccess = createAction(
  '[Contract] Update Success',
  props<{
    contract: Contract;
  }>()
);
export const updateContractFailed = createAction(
  '[Contract] Update Failed',
  props<{ error: Error }>()
);

// Delete Contract:
export const deleteContract = createAction(
  '[Contract] Delete Initiated',
  props<{ id: string }>()
);
export const deleteContractSuccess = createAction(
  '[Contract] Delete Success',
  props<{ id: string }>()
);
export const deleteContractFailed = createAction(
  '[Contract] Delete Failed',
  props<{ error: Error }>()
);
