import { createReducer, on } from '@ngrx/store';

import { Contract } from '@models';
import * as ContractActions from './contract.actions';
import { ContractState, initialContractState } from './contract.state';

const contractReducer = createReducer<ContractState>(
  initialContractState,
  on(ContractActions.createContractSuccess, (state, { contract }) => ({
    ...state,
    contract: contract,
  })),
  on(ContractActions.fetchLatestContractSuccess, (state, { contract }) => ({
    ...state,
    contract,
    fetchedAt: new Date(),
  })),
  on(ContractActions.updateContractSuccess, (state, { contract }) => ({
    ...state,
    contract: contract,
  })),
  on(ContractActions.deleteContractSuccess, (state, { id }) => ({
    ...state,
    contract: new Contract(),
  })),
  on(ContractActions.fetchLatestContractFailed, (state, { error }) => ({
    ...state,
    fetchedAt: new Date(),
  }))
);

export { contractReducer };
