import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AlertComponent } from '@components';
import { Contract } from '@models';
import { Store } from '@ngrx/store';
import { UtilService } from '@services';
import {
  createContract,
  createContractFailed,
  createContractSuccess,
  fetchLatestContract,
  selectContract,
  updateContract,
  updateContractFailed,
  updateContractSuccess,
} from '@state';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    AlertComponent,
  ],
  templateUrl: './contract.page.html',
  styleUrl: './contract.page.scss',
})
export class ContractPage implements OnInit {
  contract: Contract | null = null;
  contractForm!: FormGroup;
  isEditMode = false;
  contractId: string | null = null;
  file?: File;
  uploadPreview: SafeResourceUrl | null = null;
  loading = false;
  public error: string | null = null;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private sanitizer: DomSanitizer,
    private utils: UtilService
  ) {}

  ngOnInit(): void {
    this.store.select(selectContract).subscribe((contract) => {
      this.contract = contract;
      this.contractForm = this.fb.group({
        version: [this.contract?.version, Validators.required],
        title: [this.contract?.title, Validators.required],
        description: [this.contract?.description, Validators.required],
      });
      if (this.contract?.fileUrl)
        this.uploadPreview = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.contract.fileUrl
        );
      this.isEditMode = !!this.contract;
    });

    this.store.dispatch(fetchLatestContract());
  }

  onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      this.processFile(target.files[0]);
    }
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
      this.processFile(event.dataTransfer.files[0]);
      event.dataTransfer.clearData();
    }
  }

  processFile(file: File): void {
    if (file && file.type === 'application/pdf') {
      this.file = file;
      const reader = new FileReader();
      reader.onload = () => {
        this.uploadPreview = this.sanitizer.bypassSecurityTrustResourceUrl(
          reader.result as string
        );
      };
      reader.readAsDataURL(file);
    } else {
      this.error = 'Invalid file type. Please select a PDF.';
    }
  }

  public closePreview(): void {
    this.uploadPreview = null;
    this.file = undefined;
  }

  async onSubmit(): Promise<void> {
    try {
      if (this.contractForm.invalid) return;
      this.loading = true;
      const contractData: any = this.contractForm.value;
      await this.saveContract(contractData);
    } catch (error: any) {
      this.error = error?.error?.message ?? 'something went wrong';
    } finally {
      this.loading = false;
    }
  }

  async saveContract(contract: Contract): Promise<void> {
    if (this.isEditMode) await this.updateExpense(contract);
    else await this.createExpense(contract);
  }

  private async createExpense(contract: Contract): Promise<void> {
    await this.utils.actionsToPromise(
      createContract({ contract, file: this.file }),
      createContractSuccess,
      createContractFailed
    );
  }

  private async updateExpense(contract: Contract): Promise<void> {
    await this.utils.actionsToPromise(
      updateContract({
        contract: { ...this.contract, ...contract },
        file: this.file,
      }),
      updateContractSuccess,
      updateContractFailed
    );
  }
}
