@let user = $user | async; @let usersFetched = $usersFetched | async;

<mat-card>
  <mat-card-content>
    @if (user) {
    <div class="flex">
      <div class="left">
        <div>
          <div
            (click)="setTab('profile')"
            [class.active]="currentTab === 'profile'"
            class="tab"
          >
            Profile
          </div>
          <div
            (click)="setTab('address')"
            [class.active]="currentTab === 'address'"
            class="tab"
          >
            Address
          </div>
          <div
            (click)="setTab('documents')"
            [class.active]="currentTab === 'documents'"
            class="tab"
          >
            Documents
          </div>
          <div
            (click)="setTab('assigned-vehicle')"
            [class.active]="currentTab === 'assigned-vehicle'"
            class="tab"
          >
            Assigned Vehicle
          </div>
          <div
            (click)="setTab('trips')"
            [class.active]="currentTab === 'trips'"
            [ngClass]="{'tab': !!user.vehicle, 'tab-disabled' : !user.vehicle }"
          >
            Trips
          </div>
          <div
            (click)="setTab('driver-behavior')"
            [class.active]="currentTab === 'driver-behavior'"
            [ngClass]="{'tab': !!user.vehicle, 'tab-disabled' : !user.vehicle }"
          >
            Driver Behavior
          </div>
          <div
            (click)="setTab('inspections')"
            [class.active]="currentTab === 'inspections'"
            [ngClass]="{'tab': !!user.vehicle, 'tab-disabled' : !user.vehicle }"
          >
            Inspections
          </div>
          <div
            (click)="setTab('expenses')"
            [class.active]="currentTab === 'expenses'"
            class="tab"
          >
            Expenses
          </div>
          <div
            (click)="setTab('signed-contract')"
            [class.active]="currentTab === 'signed-contract'"
            class="tab"
          >
            Signed Contract
          </div>
        </div>

        <div class="pb-4 px-3 pe-4">
          <div class="status-row">
            <div>User Status:</div>
            <mat-icon [ngClass]="isUserApproved(user) ? 'green' : 'red'">
              {{isUserApproved(user) ? 'check_circle' : 'cancel'}}
            </mat-icon>
          </div>

          <div class="my-3">
            <hr />
          </div>

          <div class="status-row mb-2">
            <div>Profile</div>
            <mat-icon [ngClass]="isProfileApproved(user) ? 'green' : 'red'">
              {{isProfileApproved(user) ? 'check_circle' : 'cancel'}}
            </mat-icon>
          </div>
          <div class="status-row mb-2">
            <div>Address</div>
            <mat-icon [ngClass]="isAddressApproved(user) ? 'green' : 'red'">
              {{isAddressApproved(user) ? 'check_circle' : 'cancel'}}
            </mat-icon>
          </div>
          <div class="status-row mb-2">
            <div>Documents</div>
            <mat-icon [ngClass]="areDocumentsApproved(user) ? 'green' : 'red'">
              {{areDocumentsApproved(user) ? 'check_circle' : 'cancel'}}
            </mat-icon>
          </div>
          <div class="status-row mb-2">
            <div>Car Track Profile</div>
            <mat-icon [ngClass]="hasCartTrackAccount(user) ? 'green' : 'red'">
              {{hasCartTrackAccount(user) ? 'check_circle' : 'cancel'}}
            </mat-icon>
          </div>
          <div class="status-row mb-2">
            <div>Assigned Vehicle</div>
            <mat-icon [ngClass]="hasAssignedVehicle(user) ? 'green' : 'red'">
              {{hasAssignedVehicle(user) ? 'check_circle' : 'cancel'}}
            </mat-icon>
          </div>
          <div class="status-row">
            <div>Signed Contract</div>
            <mat-icon [ngClass]="hasSignedContract(user) ? 'green' : 'red'">
              {{hasSignedContract(user) ? 'check_circle' : 'cancel'}}
            </mat-icon>
          </div>
        </div>
      </div>

      @if (currentTab == 'profile') {
      <div class="right">
        <app-user-profile
          [user]="user"
          (createCarTrackProfile)="onCreateCarTrackProfile()"
        />
      </div>
      } @if (currentTab == 'address') {
      <div class="right">
        <app-user-address [user]="user" />
      </div>
      } @if (currentTab == 'documents') {
      <div class="right">
        <app-user-documents [user]="user!"> </app-user-documents>
      </div>
      } @if (currentTab == 'assigned-vehicle') {
      <div class="right">
        <app-assigned-vehicle [user]="user" (setTab)="setTab($event)">
        </app-assigned-vehicle>
      </div>
      } @if (currentTab == 'inspections') {
      <div class="right">
        <app-user-inspections [user]="user"> </app-user-inspections>
      </div>
      } @if (currentTab == 'expenses') {
      <div class="right">
        <app-user-expenses [user]="user"> </app-user-expenses>
      </div>
      } @if (currentTab == 'trips') {
      <div class="right">
        <app-trips [user]="user"></app-trips>
      </div>
      } @if (currentTab == 'driver-behavior') {
      <div class="right">
        <app-driver-behavior [user]="user"> </app-driver-behavior>
      </div>
      } @if (currentTab == 'signed-contract') {
      <div class="right">
        <app-signed-contract [user]="user" [latestContract]="contract">
        </app-signed-contract>
      </div>
      }
    </div>
    } @if (!(usersFetched)) {
    <div class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
    }
  </mat-card-content>
</mat-card>
