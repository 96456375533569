import { Signature } from './';
import { BaseModel } from './base.model';

export class Contract extends BaseModel {
  _id: string = '';
  version: string = '';
  title: string = '';
  description: string = '';
  fileUrl: string = '';
  signature?: Signature;

  constructor(data?: Partial<Contract>) {
    super();
    Object.assign(this, data);
  }
}
