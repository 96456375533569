import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { ContractService } from '@services';
import * as ContractActions from './contract.actions';

@Injectable()
export class ContractEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private contractService: ContractService
  ) {}

  // Create
  createContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.createContract),
      switchMap(({ contract, file }) =>
        from(this.contractService.createContract(contract, file)).pipe(
          map((contract) =>
            ContractActions.createContractSuccess({ contract })
          ),
          catchError((error) =>
            of(ContractActions.createContractFailed({ error }))
          )
        )
      )
    )
  );

  //Read
  fetchContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.fetchLatestContract),
      switchMap(() =>
        from(this.contractService.getLatest()).pipe(
          map((contract) =>
            ContractActions.fetchLatestContractSuccess({
              contract,
            })
          ),
          catchError((error) =>
            of(ContractActions.fetchLatestContractFailed({ error }))
          )
        )
      )
    )
  );

  // Update
  updateContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.updateContract),
      switchMap(({ contract, file }) =>
        from(this.contractService.updateContract(contract, file)).pipe(
          map((contract) =>
            ContractActions.updateContractSuccess({ contract })
          ),
          catchError((error) =>
            of(ContractActions.updateContractFailed({ error }))
          )
        )
      )
    )
  );

  // Delete
  deleteContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.deleteContract),
      switchMap(({ id }) =>
        from(this.contractService.delete(id)).pipe(
          map(() => ContractActions.deleteContractSuccess({ id })),
          catchError((error) =>
            of(ContractActions.deleteContractFailed({ error }))
          )
        )
      )
    )
  );
}
